<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL OTROS DOCUMENTOS
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-file-alt fa-3x"></i> <br>
                            <span class="lg-numero">{{listaDocumentos.length}}</span>
                            <br>
                            <span class="text-muted">Documentos</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de etiquetas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col  v-if="checkPermissions('010-MEJ-OTR','c')==1" cols="4" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarDocumento = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo documento
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-file-alt fa-md"></i><span class="h5"> Gestión de Documentos</span>

                </CCardHeader>

                <CCardBody>

                    <b-row>
                        <b-col md="12">
                            <span class="h6 text-muted"> A CONTINUACIÓN SE MUESTRA LA DOCUMENTACIÓN SUBIDA AL SISTEMA:</span>
                            <hr>
                        </b-col>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaDocumentos" :fields="camposDocumentos" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(tipoDocumento)="param">
                                    <span v-if="param.item.tipoDocumento==1">Lecciones Aprendidas</span>
                                    <span v-else-if="param.item.tipoDocumento==2">Programa de sensibilización</span>
                                    <span v-else-if="param.item.tipoDocumento==3">Programa de comunicaciones</span>
                                </template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button v-if="checkPermissions('010-MEJ-OTR','u')==1"  size="sm" v-c-tooltip.hover.click="'Ver detalles'" variant="dark" class=" mr-1 mb-1" @click="abrirModalActualizarDocumento(param)">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button  v-if="checkPermissions('010-MEJ-OTR','d')==1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarDocumento(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>

                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :show.sync="modalRegistrarDocumento" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo documento </span>
            </h6>
            <CButtonClose @click="modalRegistrarDocumento = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarDocumento)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosDocumento.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo_documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumento =>comboTipoDocumento.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDocumento.tipoDocumento  , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDocumento.tipoDocumento " :options="comboTipoDocumento" @search:blur="blurTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de publicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de publiación:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de publicación" v-model="datosDocumento.fechaPublicacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Documento:">
                            <b-form-file ref="file" v-model="datosDocumento.documento" v-on:change="handleFileUpload" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarDocumento = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarDocumento" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar documento</span></h6>

            <CButtonClose @click="modalActualizarDocumento = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarDocumento)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosActualizarDocumento.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo_documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumento =>comboTipoDocumento.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarDocumento.tipoDocumento  , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarDocumento.tipoDocumento " :options="comboTipoDocumento" @search:blur="blurTipoActualizarDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de publicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de publiación:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de publicación" v-model="datosActualizarDocumento.fechaPublicacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Documento:" class="mb-2">
                            <b-input-group>
                                <b-input-group-prepend v-if="datosActualizarDocumento.urlDocumento != ''">
                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarDocumento.urlDocumento)" v-c-tooltip="'Descargar'">
                                        <i class="fas fa-download fa-xs"></i>
                                    </b-button>
                                </b-input-group-prepend>
                                <b-form-file ref="fileActualizar" v-model="datosActualizarDocumento.documento" v-on:change="handleFileUploadActualizar" :placeholder="datosActualizarDocumento.nombreDocumento ? datosActualizarDocumento.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosActualizarDocumento.nombreDocumento ? datosActualizarDocumento.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarDocumento = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            datosDocumento: {
                idOtroDocumento: '',
                nombre: '',
                tipoDocumento: null,
                fechaPublicacion: moment().format('YYYY-MM-DD'),
                urlDocumento: '',
                nombreDocumento: '',
                documento: null
            },
            datosActualizarDocumento: {
                idOtroDocumento: '',
                nombre: '',
                tipoDocumento: null,
                fechaPublicacion: '',
                urlDocumento: '',
                nombreDocumento: '',
                documento: null
            },
            comboTipoDocumento: [{
                    idTipoDocumento: 1,
                    descripcion: "Lecciones Aprendidas"
                },
                {
                    idTipoDocumento: 2,
                    descripcion: "Programa de sensibilización"
                },
                {
                    idTipoDocumento: 3,
                    descripcion: "Programa de comunicaciones"
                }
            ],

            listaDocumentos: [],

            camposDocumentos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center"
                },
                {
                    key: "tipoDocumento",
                    label: "Tipo",
                    class: "text-center"
                },
                {
                    key: "fechaPublicacion",
                    label: "Fecha de Publicación",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                },
            ],

            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarDocumento: false,
            modalActualizarDocumento: false,
            datosSession: {
                idCliente: ''
            },
            disabled: false

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurTipoDocumento() {
            this.computedForm.refs.tipo_documento.validate();
        },
        blurTipoActualizarDocumento() {
            this.computedActualizarForm.refs.tipo_documento.validate();
        },
        descargarDocumento(url) {
            window.open(url)
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                this.datosDocumento.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadActualizar(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar"].reset();
                    return;
                }

                this.datosActualizarDocumento.documento = this.$refs.fileActualizar.files[0];
            }
        },
        listarOtrosDocumentos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-otros-documentos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaDocumentos = response.data;
                    me.filasTotales = me.listaDocumentos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarDocumento() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosDocumento.documento);
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'mejora-continua/otros-documentos');

            formData.append("nombre", me.datosDocumento.nombre);
            formData.append("tipoDocumento", me.datosDocumento.tipoDocumento);
            formData.append("fechaPublicacion", me.datosDocumento.fechaPublicacion);
            formData.append("urlDocumento", me.datosDocumento.urlDocumento);
            formData.append("nombreDocumento", me.datosDocumento.nombreDocumento);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-otro-documento",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarOtrosDocumentos();
                    me.modalRegistrarDocumento = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarDocumento() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosActualizarDocumento.documento);
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'mejora-continua/otros-documentos');

            formData.append("idOtroDocumento", me.datosActualizarDocumento.idOtroDocumento);
            formData.append("nombre", me.datosActualizarDocumento.nombre);
            formData.append("tipoDocumento", me.datosActualizarDocumento.tipoDocumento);
            formData.append("fechaPublicacion", me.datosActualizarDocumento.fechaPublicacion);
            formData.append("urlDocumento", me.datosActualizarDocumento.urlDocumento);
            formData.append("nombreDocumento", me.datosActualizarDocumento.nombreDocumento);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-otro-documento",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarOtrosDocumentos();
                    me.modalActualizarDocumento = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        abrirModalActualizarDocumento(param) {
            this.datosActualizarDocumento.idOtroDocumento = param.item.idOtroDocumento;
            this.datosActualizarDocumento.nombre = param.item.nombre;
            this.datosActualizarDocumento.tipoDocumento = param.item.tipoDocumento;
            this.datosActualizarDocumento.fechaPublicacion = param.item.fechaPublicacion;
            this.datosActualizarDocumento.urlDocumento = param.item.urlDocumento;
            this.datosActualizarDocumento.nombreDocumento = param.item.nombreDocumento;
            this.modalActualizarDocumento = true;
        },
        resetModalRegistrarDocumento() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosDocumento.nombre = '';
            this.datosDocumento.tipoDocumento = null;
            this.datosDocumento.fechaPublicacion = '';
            this.datosDocumento.documento = null;

        },
        resetModalActualizarDocumento() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        eliminarDocumento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-otro-documento", {
                            idOtroDocumento: param.item.idOtroDocumento,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarOtrosDocumentos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarDocumento: function (val) {
            if (val == false) {
                this.resetModalRegistrarDocumento();
            }
        },
        modalActualizarDocumento: function (val) {
            if (val == false) {
                this.resetModalActualizarDocumento();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;

            this.listarOtrosDocumentos();
        }
    }

}
</script>
